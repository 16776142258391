(function () {
  headerMenu();
  
  function headerMenu() {
    var menu = $("#showMainMenu");

    menu.click(function () {
      if ($("html").hasClass("block-html")) {
        $("html").removeClass("block-html");
      } else {
        $("html").addClass("block-html");
      }
    });
  }
})();